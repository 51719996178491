import {
  InformationCircleIcon,
  BadgeCheckIcon,
  BookOpenIcon,
  CashIcon,
} from "@heroicons/react/outline";

export const Quality = ({}: {
  name?: string;
  number?: number;
}): JSX.Element => {
  return (
    <div className="flex flex-col p-8 bg-white">
      <div className="flex flex-col w-full items-center">
        <span className="text-3xl text-ink">
          Giá trị nhận được khi tham gia
        </span>
        <span className="text-3xl text-primary-pink">Lớp học 1 tô</span>
      </div>
      <div className="flex flex-row py-8 mx-auto max-w-7xl xl:px-4 lg:px-10 space-x-6">
        <div className="flex flex-col space-y-6 w-1/2">
          <div className="flex flex-row space-x-4">
            <InformationCircleIcon
              className="w-20 h-20 text-gray-400"
              aria-hidden="true"
            />
            <div className="flex flex-col space-y-2">
              <span className="text-lg text-ink font-medium">
                Nội dung xây dựng từ cuộc sống
              </span>
              <span className="text-smoke">
                Giúp người học khám phá cuộc sống xung quanh mình, dần trở thành
                một người sành sỏi. Gợi ý cho người học những kỹ năng cơ bản cần
                rèn luyện.
              </span>
            </div>
          </div>
          <div className="flex flex-row space-x-4">
            <BadgeCheckIcon
              className="w-20 h-20 text-gray-400"
              aria-hidden="true"
            />
            <div className="flex flex-col space-y-2">
              <span className="text-lg text-ink font-medium">
                Mở khóa vĩnh viễn, cập nhật liên tục
              </span>
              <span className="text-smoke">
                Người học sở hữu bài học vĩnh viễn sau khi mở khóa. Nội dung bài
                học sẽ liên tục được cập nhật khi có kiến thức mới có liên quan
                bài học được khám phá thêm.
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-6 w-1/2">
          <div className="flex flex-row space-x-4">
            <BookOpenIcon
              className="w-20 h-20 text-gray-400"
              aria-hidden="true"
            />
            <div className="flex flex-col space-y-2">
              <span className="text-lg text-ink font-medium">
                Kết nối với giảng viên, tư vấn trực tiếp
              </span>
              <span className="text-smoke">
                Tài liệu tham khảo phong phú, giúp mở rộng nội dung bài học.
                Người học có thể liên lạc với giảng viên để mở rộng thêm vấn đề
                đã được học.
              </span>
            </div>
          </div>
          <div className="flex flex-row space-x-4">
            <CashIcon className="w-20 h-20 text-gray-400" aria-hidden="true" />
            <div className="flex flex-col space-y-2">
              <span className="text-lg text-ink font-medium">
                Chi phí hợp lý, giao diện thân thiện
              </span>
              <span className="text-smoke">
                Giao diện được thiết kế sáng sủa, dễ sử dụng. Chi phí học tập
                thấp hơn tô hủ tíu gõ bình dân nhất như thương hiệu ‘Lớp Học 1
                Tô’.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
