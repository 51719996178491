import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { sendServerAnalytics } from "services/analytics";
import { IStore } from "stores/types";
import CourseItem from "views/Courses/CourseItem";
import { CoursePopup } from "views/Courses/CoursePopup";

export const FeatureClass = ({ name }: { name?: string }): JSX.Element => {
  const { topicPopupOpen } = useSelector((store: IStore) => store.topic);
  const [courses, setCourses] = useState<any[]>([]);

  const getCourses = async () => {
    try {
      const res = await axios.get(
        "/api/v1/lesson?limit=6&ordering=-created_on",
        {
          withCredentials: true,
        }
      );
      setCourses(res.data?.results ?? []);
    } catch (error) {}
  };

  useEffect(() => {
    getCourses();
  }, []);
  return (
    <>
      {topicPopupOpen && <CoursePopup />}
      <div className="flex flex-col p-8 space-y-10">
        <div className="flex flex-row justify-center w-full text-3xl font-semibold font-gray-700">
          {name ?? "Lớp học nổi bật"}
        </div>
        <div className="flex flex-row justify-center">
          <div className="grid flex-1 max-w-5xl grid-cols-3 gap-6 ">
            {courses.map((_) => (
              <CourseItem data={_} key={_?.id} />
            ))}
          </div>
        </div>
        <div className="flex flex-row justify-center w-full text-3xl font-semibold font-gray-700">
          <button
            className="px-2 py-3 text-lg text-white rounded-sm rounded-l-md w-max bg-primary-pink border-primary-pink hover:bg-primary-bolder hover:border-primary-bolder"
            type="button"
            onClick={async () => {
              await sendServerAnalytics(
                "click-see-more-course",
                "homepage",
                {}
              );
              window.location.href = "/courses";
            }}
          >
            Xem thêm bài học
          </button>
        </div>
      </div>
    </>
  );
};
