/* eslint-disable @next/next/no-img-element */
import { Combobox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import axios from "axios";
import { useRouter } from "next/router";
import { Fragment, useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import { sendServerAnalytics } from "services/analytics";

const SearchForm = (): JSX.Element => {
  const router = useRouter();
  const [searchText, setSearchText] = useState<string>("");
  const [categories, setCategories] = useState<any[]>([]);

  const [selected, setSelected] = useState<any>();
  const [query, setQuery] = useState("");

  const filteredCategory = useMemo(() => {
    return categories.filter((category) =>
      category?.title?.toLowerCase()?.includes?.(query)
    );
  }, [categories, query]);

  const getCategories = async () => {
    try {
      const res = await axios.get("/api/v1/category?limit=100");
      setCategories(res.data?.results ?? []);
    } catch (c) {}
  };

  const [selectedLevel, setSelectedLevel] = useState();

  useEffect(() => {
    getCategories();
  }, []);

  const handleSearch = async () => {
    try {
      const searchObj: any = {};
      if (selected) {
        searchObj["category"] = selected.slug;
      }

      if (selectedLevel) {
        searchObj["point"] = selectedLevel;
      }

      if (searchText) {
        searchObj["search"] = searchText;
      }

      await sendServerAnalytics("search", "homepage filter", searchObj);
      router.push("/courses?" + queryString.stringify(searchObj));
    } catch (error) {}
  };

  return (
    <div className="flex justify-center w-full">
      <div
        style={{
          width: 980,
          boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.05)",
          marginTop: "-80px",
          zIndex: 10,
        }}
        className="flex flex-col p-8 space-y-4 bg-white rounded-lg text-ink "
      >
        <span className="flex text-2xl font-semibold text-ink">
          Bạn đang muốn tìm khóa học?
        </span>
        <div className="flex flex-row space-x-4 text-base">
          <input
            id="search"
            value={searchText}
            name="search"
            className="block w-full h-12 py-2 pr-3 text-base leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:border-primary-pink focus:ring-0"
            placeholder="Khóa học bạn cần tìm?"
            type="search"
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Combobox value={selected} onChange={setSelected}>
            <div className="relative">
              <div className="relative flex flex-col justify-center w-56 h-12 overflow-hidden text-left bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:border-primary-pink focus:ring-0">
                <Combobox.Input
                  className="w-full py-2 pl-3 pr-10 leading-5 text-gray-900 border-none focus:ring-0"
                  displayValue={(person: any) => person?.title ?? "Chủ đề"}
                  onChange={(event) => setQuery(event.target.value)}
                  placeholder="Chủ đề"
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <SelectorIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
              >
                <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredCategory.length === 0 && query !== "" ? (
                    <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                      Nothing found.
                    </div>
                  ) : (
                    filteredCategory.map((person) => (
                      <Combobox.Option
                        key={person.id}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active ? "bg-teal-600 text-white" : "text-gray-900"
                          }`
                        }
                        value={person}
                      >
                        {({ selected, active }) => (
                          <div className="flex flex-row items-center justify-between ">
                            <div
                              className={`flex flex-col space-y-px truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              <div>{person.title}</div>
                            </div>
                            {selected ? (
                              <div
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? "text-white" : "text-teal-600"
                                }`}
                              >
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </div>
                            ) : null}
                          </div>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
          <Combobox value={selected} onChange={setSelectedLevel}>
            <div className="relative">
              <div className="relative w-56 h-12 py-2 pr-3 overflow-hidden text-left bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:border-primary-pink focus:ring-0">
                <Combobox.Button className="inset-y-0 right-0 flex flex-row items-center justify-between w-full pr-2">
                  <div
                    className="flex flex-row w-full py-2 pl-3 pr-10 space-x-2 text-sm leading-5 text-gray-900 border-none focus:ring-0"
                    // onChange={(event) => setQuery(event.target.value)}
                    // placeholder="Cấp độ"
                  >
                    {(selectedLevel ?? 0) > 0 ? (
                      Array(selectedLevel ?? 0)
                        .fill(0)
                        .map((_, idx) => (
                          <div key={"to-" + idx} className="w-6 cursor-pointer">
                            <img
                              src="/images/to.png"
                              //   width="200px"
                              width="23"
                              alt="ga-welcome"
                              style={{
                                height: 20,
                              }}
                            />
                          </div>
                        ))
                    ) : (
                      <div
                        className="font-normal text-gray-600 "
                        style={{
                          fontSize: 16,
                          marginBottom: 1,
                        }}
                      >
                        Cấp độ
                      </div>
                    )}
                  </div>
                  <SelectorIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
              >
                <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {Array(3)
                    .fill(0)
                    .map((_, idx) => (
                      <Combobox.Option
                        key={idx}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active ? "bg-teal-600 text-white" : "text-gray-900"
                          }`
                        }
                        value={idx + 1}
                      >
                        {({ selected, active }) => (
                          <div className="flex flex-row items-center justify-between cursor-pointer">
                            <div
                              className={`flex flex-row space-x-2 truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {Array(idx + 1)
                                .fill(0)
                                .map((_, idx) => (
                                  <div key={"to-" + idx} className="w-6">
                                    {/* 🍜 */}
                                    {/* 🥘 */}
                                    <img
                                      src="/images/to.png"
                                      //   width="200px"
                                      // height="15"
                                      // height={"auto" }
                                      width="20"
                                      // layout="responsive"
                                      alt="ga-welcome"
                                      style={{
                                        height: 18,
                                      }}
                                    />
                                  </div>
                                ))}
                            </div>
                            {selected ? (
                              <div
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? "text-white" : "text-teal-600"
                                }`}
                              >
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </div>
                            ) : null}
                          </div>
                        )}
                      </Combobox.Option>
                    ))}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
          <button
            className="w-40 btn bg-primary-pink border-primary-pink hover:bg-primary-bolder hover:border-primary-bolder"
            type="button"
            onClick={handleSearch}
          >
            Tìm kiếm
          </button>
        </div>
      </div>
    </div>
  );
};

export const Heading = ({}: { view: string }): JSX.Element => {
  return (
    <div style={{ marginTop: "-2.5rem" }} className="relative text-white">
      <div
        style={{
          height: "70vh",
          backgroundImage: 'url("/images/landing-pages/top-banner.jpeg")',
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundPositionY: "-20px",
          filter: "brightness(20%)",
        }}
      />
      <div
        style={{ top: "20vh" }}
        className="absolute flex flex-col items-center w-full"
      >
        <span className="text-4xl font-extrabold tracking-tight text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
          Góp đầy Sàng Khôn
        </span>
        <span className="mt-4 text-4xl font-extrabold tracking-tight text-primary-pink sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
          cùng Lớp học 1 tô
        </span>
      </div>
      <SearchForm />
    </div>
  );
};
